import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {useOutlet} from 'reconnect.js';
import qs from 'query-string';
import {Helmet} from 'react-helmet';
import RichTextPreview from 'rev.sdk.js/Components/RichTextPreview';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';
import Theme from '../../Theme';

function ArticleDetail(props) {
  const {
    pageContext: {
      listViewPath = '/articles',
      prefixPath = '/article',
      prebuiltArticle = null,
    },
  } = props;
  const [dimension] = useOutlet('dimension');
  const [article, setArticle] = React.useState(prebuiltArticle);
  const params = qs.parse(props.location.search);
  const {id} = params;
  const mobile = dimension.innerWidth < 720;
  const isPrebuilt = !!prebuiltArticle;

  React.useEffect(() => {
    async function fetchData() {
      try {
        AppActions.setLoading(true);
        setArticle(await JStorage.fetchOneDocument('Article_Default', {id}));
      } catch (ex) {
      } finally {
        AppActions.setLoading(false);
      }
    }

    if (!isPrebuilt) {
      fetchData();
    }
  }, [id, isPrebuilt]);

  return (
    <PageWrapper>
      {article && (
        <Helmet>
          <meta charset="utf-8" />
          <meta property="og:title" content={article.title || ''} />
          <meta property="og:image" content={article.image || ''} />
          <meta property="og:image:width" content="300" />
          <meta property="og:image:height" content="300" />
          <meta name="title" content={article.title || ''} />
          <meta name="description" content={article.outline || ''} />
          <meta name="image" content={article.image || ''} />
        </Helmet>
      )}

      <div className="content">
        <div className="detail">
          <div
            style={{display: 'flex', flexDirection: mobile ? 'column' : 'row'}}>
            {article && (
              <div className="main">
                <div className="article-metadata">
                  <h2 className="article-title">{article.title || '無標題'}</h2>
                  <p className="article-label">
                    發佈時間：
                    {moment(article.created).format('YYYY-MM-DD HH:MM')}
                  </p>
                  <p className="article-label">
                    更新時間：
                    {moment(article.updated).format('YYYY-MM-DD HH:MM')}
                  </p>
                </div>
                <div className="article-content">
                  <RichTextPreview content={article.content} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {article?.web_action_url && (
        <CTA>
          <div className="content">
            <a
              href={article.web_action_url}
              target="_blank"
              rel="noreferrer"
              onClick={(e) => {
                if (article.web_action_url[0] === '/') {
                  e.preventDefault();
                  AppActions.navigate(article.web_action_url);
                }
              }}>
              {article.action_label || '馬上前往'}
            </a>
          </div>
        </CTA>
      )}
    </PageWrapper>
  );
}

const CTA = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  background-color: ${Theme.colors.bgYellow};
  border-top: 5px solid ${Theme.colors.main};

  & > .content {
    max-width: 768px;
    margin: 0 auto;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    & > a {
      outline: none;
      border: none;
      padding: 8px 15px;
      background-color: ${Theme.colors.main};
      color: white;
      font-size: 1.33rem;
      border-radius: 8px;
      cursor: pointer;
    }
  }
`;

const PageWrapper = styled.div`
  padding-top: var(--topNavBarHeight);
  max-width: var(--contentMaxWidth);
  margin: 0 auto;

  & > .content {
    max-width: var(--contentMaxWidth);
    margin: 0 auto;
    display: flex;

    & > .categories {
      padding-top: 22px;
      display: flex;
      flex-direction: column;

      & > h3 {
        border-bottom: 1px solid #ccc;
        padding: 4px 8px;
        align-self: center;
      }
    }

    & > .detail {
      flex: 1;
      padding: var(--basePadding);
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  @media (max-width: 720px) {
    & > .content {
      flex-direction: column-reverse;

      & > .categories {
        & > ul {
          width: 100% !important;
        }
      }
    }
  }

  & .main {
    max-width: 800px;
    margin: 20px auto;
    flex: 1;

    & .article-metadata {
      border-bottom: 1px solid lightgray;
      margin-bottom: 20px;
      padding-bottom: 20px;

      & h2.article-title {
        font-size: 2.2rem;
        font-weight: 600;
        line-height: 1.5;
        margin-bottom: 20px;
        color: ${Theme.colors.main};
      }

      & img.article-image {
        max-width: 100%;
        width: 100%;
        margin-bottom: 20px;
      }

      & p.article-label {
        line-height: 1.5;
        font-weight: 400;
      }
    }

    & .article-content {
    }
  }
`;

export default ArticleDetail;
